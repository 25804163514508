import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Roadmap from './components/roadmap/Roadmap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/main/main';
import Team from './components/team/Team';
import './lang/i18n';
import Advisor from './components/team/Advisor';

function App() {

	const [toTop, setToTop] = useState('');

	function scrollToTop(){
		window.scroll({behavior:'smooth',top:0})
	}

	function visiableButton(){

		if(window.scrollY > 100 && window.outerWidth > 767){
			setToTop(' to-top-open');
		}else {
			setToTop('');
		}
	}

  useEffect(()=>{
		window.addEventListener('scroll', visiableButton);
		return ()=>{window.removeEventListener('scroll', visiableButton)}
  })


  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route exact path="/"  element={<Main/>}/>
          <Route exact path="/roadmap" element={<Roadmap/> }/>
          <Route exact path="/team" element={<Team/>}/>
          <Route exact path="/advisor" element={<Advisor/>}/>
        </Routes>
        <button lang="en" id="toTop" className={toTop} onClick={scrollToTop}>UP</button>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
