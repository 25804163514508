import { useDebugValue, useEffect, useState } from 'react';
import '../css/Header.css';
import '../css/Header_res.css'
import i18n from '../lang/i18n';
import { Link } from 'react-router-dom';
// import HeaderLogo from '../img/logo_tgn.png';
import HeaderLogoW from '../img/logo_glovein_w.png';
import HeaderLogoB from '../img/logo_glovein_b.png';

function Header(){

	// open mobile menu S
	const [hambergerOpen, setHambergerOpen] = useState('');
	const [openNav , setOpenNav] = useState('');
	const [headerBack, setHeaderBack] = useState('');
	const [ btnKo , setBtnKo ] = useState(false)
	const [ btnEn , setBtnEn ] = useState(true)
	const [ btnCn , setBtnCn ] = useState(false)

	function toggleMobileNav(){

		if(hambergerOpen === ''){
			setHambergerOpen(' opened');
			setOpenNav(' open-nav')
		}else {
			setHambergerOpen('')
			setOpenNav('')
			closeShowNavList();
		}
	}
	function closeMobileNav(){
		console.log('akjdfa');
		if(hambergerOpen !== ''){
			setHambergerOpen('')
			setOpenNav('')
			closeShowNavList();
		}
	}

	// open mobile menu E

	// click menu link S
	async function onclickLinkEvent(e){
		await closeMobileNav()
		const href = e.target.getAttribute('href')
		if(href === '/#about'){
			window.scroll({'behavior':'auto','top': document.querySelector('#about').offsetTop})
			return
		}else if(href === '/#platform'){
			window.scroll({'behavior':'auto','top': document.querySelector('#platform').offsetTop})
			return
		}
		await window.scroll({'behavior':'auto','top':0})
	}
	// click menu link E

	// mobile members list show S
	function toggleShowNavList(e) {
		let element = e.target;
		if(element.className.indexOf(' show') === -1){
			element.className = element.className + ' show';
		}else{
			element.className = element.className.replace(' show','').trim();
		}
	}
	function closeShowNavList() {
		const element = document.querySelector('#gnb ul a.show');
		console.log(element);
		if(element){
			element.className = element.className.replace(' show','').trim();
		}
	}
	// mobile members list show E

	// 언어 변경 S
	const changelanguageToKo = () => i18n.changeLanguage('ko');
	const changelanguageToEn = () => i18n.changeLanguage('en');
	const changelanguageToCn = () => i18n.changeLanguage('cn');

	function toggleLanguageChanger(){
		let lang = document.querySelector('html').getAttribute('lang');

		if(lang === 'ko'){
			changHtmlToEn();
		}else {
			changHtmlToKo();
		}
	}

	function changHtmlToKo(){
		document.querySelector('html').setAttribute('lang','ko');
		setBtnKo(true);
		setBtnEn(false);
		setBtnCn(false);
		changelanguageToKo();
	}

	function changHtmlToCn(){
		document.querySelector('html').setAttribute('lang','zh-CN');
		setBtnKo(false);
		setBtnEn(false);
		setBtnCn(true);
		changelanguageToCn();
	}

	function changHtmlToEn(){
		document.querySelector('html').setAttribute('lang','en');
		setBtnKo(false);
		setBtnEn(true);
		setBtnCn(false);
		changelanguageToEn();
	}
	// 언어 변경 S

	// scroll down animation S
	const [scroll, setScroll] = useState('');

	function scrollAnimation(){
		if(window.scrollY > 100){
			setScroll('scroll');
		}else {
			setScroll('');
		}
	}
	// scroll down animation E

	// team button prevent S
	function readyToPage(e){
		e.preventDefault()
		alert('준비 중입니다.');
	}
	// team button prevent E

	useEffect(()=>{
		window.addEventListener('scroll', scrollAnimation);
		return ()=>{window.removeEventListener('scroll',scrollAnimation)};
	});


	return (
		<header lang="en" className={scroll + openNav}>
			<div className="container">
				<div className="header-logo">
					<Link to="/" onClick={onclickLinkEvent}>
						<img src={HeaderLogoW} alt="GLOVEIN 로고"></img>
						<img src={HeaderLogoB} alt="GLOVEIN 로고"></img>
					</Link>
				</div>
				<button onClick={toggleMobileNav} className={'hamberger mb-d-view'+hambergerOpen}>
					<div className="line line-1"></div>
					<div className="line line-2"></div>
					<div className="line line-3"></div>
				</button>

				<nav id="gnb">
					<h2 className="blind">메뉴</h2>
					<ul>
						<li>
							{
								document.querySelector('html').getAttribute('lang') === 'zh-CN'?
									(<Link onClick={onclickLinkEvent} to="/#about">关于我们</Link>):
									(<Link onClick={onclickLinkEvent} to="/#about">ABOUT</Link>) 
							}
						</li>
						<li>
							<Link onClick={onclickLinkEvent} to="/#platform">PLATFORM</Link>
							{/* <a onClick={closeMobileNav} href="/#platform">PLATFORM</a> */}
						</li>
						<li>
							<Link to="/roadmap" onClick={onclickLinkEvent}>
								ROADMAP
								{/* <a href="true">ROADMAP</a> */}
							</Link>
						</li>
						
						{/* <li> 팀어드바이저 확정시 노출하기 22.12.08
							{ window.innerWidth > 766?
								<Link onClick={onclickLinkEvent} to="/team">MEMBER</Link> 
								: <a href="#none" data-nav-id="member" onClick={toggleShowNavList} >MEMBER</a> }
							<div className='member'>
								<Link className='member' onClick={onclickLinkEvent} to="/team">Team</Link>
								<Link className='member' onClick={onclickLinkEvent} to="/advisor">Advisor</Link>
							</div>
							
						</li> */}
						{/* {<a aria-label="button" href="true" onClick={readyToPage} style={{"cursor":"pointer"}}>TEAM</a>}
						{<a href aria-label="button" onClick={toggleLanguageChanger} style={{"cursor":"pointer"}}>TEAM</a>} */}
						
					</ul>
					<div className="wrap-btn-change_lang">
						<h3 className="blind">언어 변경 버튼, language change button</h3>
						<button id="en" onClick={changHtmlToEn} disabled={btnEn}>EN</button>
						{/* <button id="cn" onClick={changHtmlToCn} disabled={btnCn}>CN</button> */}
						<button id="kr" onClick={changHtmlToKo} disabled={btnKo}>KR</button>
					</div>
				</nav>
			</div>
		</header>
	);
}

export default Header;