import { useTranslation } from 'react-i18next';
import '../../css/main/About.css';
import '../../css/main/About_res.css';
import WalletImg from '../../img/wadex_wallet_img.jpg';
import platformImg01 from '../../img/tgn_art_platform_01.png';
import platformImgEn01 from '../../img/tgn_art_platform_en_01.png';
import platformImg02 from '../../img/wadex_art_platform_02.png';
import platformImgEn02 from '../../img/wadex_art_platform_en_02.png';
import bg_art_platform from '../../img/bg_art_platform.png';
import { useState } from 'react';



export default function About(){

	const { t } = useTranslation()


	// function setImgByLang(imgName){
	// 	const lang = document.querySelector('html').lang;
	// 	if(imgName === '01'){
	// 		lang === 'ko'?
	// 	}
	// 	if(lang === 'ko'){

	// 	}
	// 	return 
	// }


	return (
	<section id="about">
		<div className="container">
			<h2 lang="en">ABOUT</h2>
			<h3 lang="en">What is GLOVEIN?</h3>
			<p className="txt">
				{t('main_about-itr-00').split('|')[0]}
				<b>{t('main_about-itr-00').split('|')[1]}</b>
				{t('main_about-itr-00').split('|')[2]}
			</p>
			<div className="contents">
				<div>
					<p lang="en">Digital Blockchain Social Network Exchange</p>
				</div>
				<div className="box-x"></div>
				<div>
					<p lang="en">Service For Fans And Influencer</p>
				</div>
			</div>
		</div>
		<div className="wrap-features-container">
			<div className="container" id="traFeatures">
				<h3 lang="en">GLOVEIN Features</h3>
				<p>{t('main_about-features-00')}</p>
				<ul>
					<li>
						<span/>
						<p>{t('main_about-features-01')}</p>
					</li>
					<li>
						<span/>
						<p>{t('main_about-features-02')}</p>
					</li>
					<li>
						<span/>
						<p>{t('main_about-features-03')}</p>
					</li>
					<li>
						<span/>
						<p>{t('main_about-features-04')}</p>
					</li>
				</ul>
			</div>
		</div>
		
		<div className="wrap-vision-container">
			<div className="container" id="traVision">
				<p lang="en">WHY GLOVEIN</p>
				<h3 lang="en">GLOVEIN Vision</h3>
				<ol>
					<li>
						<p>01</p>
						<p>{t('main_about-vision-00')}</p>
					</li>
					<li>
						<p>02</p>
						<p>{t('main_about-vision-01')}</p>
					</li>
					<li>
						<p>03</p>
						<p>{t('main_about-vision-02')}</p>
					</li>
					<li>
						<p>04</p>
						<p>{t('main_about-vision-03')}</p>
					</li>
				</ol>
			</div>
		</div>
			{/* <div id="blockChainSystem">
				<div className="wrap-block-chain-tit">
					<div className="container">
						<h3 lang="en">GLOV TOKEN Eco-System</h3>
						<p>{t('main_about-blockChainSystem-00')}</p>
					</div>
				</div>
				<div className="wrap-scroll-slide">
					<div className="wrap-left-slide">
						<div className="left-slide-img slide-img-01">
							<picture>
							
								<img src={ 
									document.querySelector('html').lang==='ko'?platformImg01:platformImgEn01
									} alt=""/>
							</picture>

						</div>
						<div className="left-slide-img slide-img-02">
							<picture>
								<img src={ 
									document.querySelector('html').lang==='ko'?platformImg02:platformImgEn02
									} alt=""/>
							</picture>
						</div>
					</div>
					<div className="wrap-right-slide">
						<img src={bg_art_platform} alt=""/>
						<div className="right-slide-txt slide-txt-01">
							<h3>WADEX Blockchain System</h3>
							<p>{t('main_about-blockChainSystem-01')}</p>
						</div>
						<div className="right-slide-txt slide-txt-02">
							<h3>WADEX Blockchain System</h3>
							<p>{t('main_about-blockChainSystem-02')}</p>
						</div>
					</div>
				</div>
			</div> */}

	</section>
	)
}