import '../../css/team/Team.css';
import '../../css/team/Team_res.css';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import memberImg01 from "../../img/team/jin_woo_lee.png";
import memberImg02 from "../../img/team/hyo_sung_lee.png";
import memberImg03 from "../../img/team/paik_yang.png";
import memberImg04 from "../../img/team/in_yong_jung.png";
import memberImg05 from "../../img/team/hyo_seok_thomas_son.png";
import memberImg06 from "../../img/team/chang_heum_yoon.png";



export default function Team(){

	const { t } = useTranslation();


	useEffect(()=>{
		const header = document.querySelector('header');
		header.setAttribute('data-vi','true');
	})

	const PEOPLE = [
		{
			img: memberImg01,
			rank: 'CEO',
			name: t('member-team-CEO--name'),
			careers : [
				t('member-team-CEO--career01'),
				t('member-team-CEO--career02'),
				t('member-team-CEO--career03'),
				t('member-team-CEO--career04')
			]
		},
		{
			img: memberImg02,
			rank: 'CSO',
			name: t('member-team-CSO--name'),
			careers : [
				t('member-team-CSO--career01'),
				t('member-team-CSO--career02'),
				t('member-team-CSO--career03')
			]
		},
		{
			img: memberImg03,
			rank: 'COO',
			name: t('member-team-COO--name'),
			careers : [
				t('member-team-COO--career01'),
				t('member-team-COO--career02'),
				['sub', t('member-team-COO--career02-sub-1')],
				t('member-team-COO--career03'),
				t('member-team-COO--career04'),
				t('member-team-COO--career05'),
				t('member-team-COO--career06'),
				['element', t('member-team-COO--career07')],
				t('member-team-COO--career08'),
				t('member-team-COO--career09'),
				[ 'sub', t('member-team-COO--career09-sub01') ],
				t('member-team-COO--career10'),
				[ 'sub', t('member-team-COO--career10-sub01') ],
			]
		},
		{
			img: memberImg04,
			rank: 'CIO',
			name: t('member-team-CIO--name'),
			careers : [
				t('member-team-CIO--career01'),
				t('member-team-CIO--career02'),
				t('member-team-CIO--career03'),
				t('member-team-CIO--career04'),
				t('member-team-CIO--career05'),
				t('member-team-CIO--career06'),
			]
		},
		{
			img: memberImg05,
			rank: 'CLO',
			name: t('member-team-CLO--name'),
			careers : [
				t('member-team-CLO--career01'),
				t('member-team-CLO--career02'),
				t('member-team-CLO--career03'),
				t('member-team-CLO--career04'),
				['sub', t('member-team-CLO--career04-sub01') ],
				t('member-team-CLO--career05'),
				['sub', t('member-team-CLO--career05-sub01') ],
				['sub', t('member-team-CLO--career05-sub02') ],
				t('member-team-CLO--career06'),
				t('member-team-CLO--career07'),
				t('member-team-CLO--career08'),
			]
		},
		// {
		// 	img: memberImg06,
		// 	rank: 'Chief Engineer',
		// 	name: t('member-team-CeEng--name'),
		// 	careers : [
		// 		t('member-team-CeEng--career01'),
		// 		t('member-team-CeEng--career02'),
		// 		t('member-team-CeEng--career03'),
		// 		t('member-team-CeEng--career04')
		// 	]
		// },
	]

	function profile(data) {

		function showCareer(career) {
			if (career && typeof career === "object" ) {
				if (career[0] === 'sub') {
					return <p className='profile-item__career-sub'>{career[1]}</p>
				}
				switch (career[1]) {
					case '<br/>':
						return <br/>
				
					default:
						return <p className='profile-item__career'></p>
				}
			}

			return <p className='profile-item__career'>{career}</p>
		}

		return (
			<li className='team__profile-item'>
				<div className='profile-item'>
					<div className='profile-item__img'>
						<img src={data.img}/>
					</div>
					<div className='profile-item__content'>
						<p className='profile-item__name'> <strong>{data.name}</strong> <span>{data.rank}</span> </p>
						{data.careers.map((item,key) => showCareer(item)) }
					</div>
				</div>
			</li>
		);
	}

	return (
		<section id="team">
			<div className='container'>
				<h1 className='team__title'>WADEX Team</h1>
				<ul className='team__profile-list'>
					{PEOPLE.map(item => profile(item))}
				</ul>
			</div>
		</section>
	);
}
