import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import '../../css/roadmap/Roadmap.css';
import '../../css/roadmap/Roadmap_res.css';
import roadmapImg from '../../img/roadmap_img.png';
import roadmapImgM01 from '../../img/roadmap_img_m_01.png';
import roadmapImgM02 from '../../img/roadmap_img_m_02.png';

export default function Roadmap(){

	const { t } = useTranslation();

	useEffect(()=>{
		const header = document.querySelector('header');
		header.setAttribute('data-vi','true');
	})

	return (
		<section id="roadMap">
			<div className="container">
				<h1> GLOVEIN Roadmap </h1>
				<div className="contents">
					{window.innerWidth > 767
					? <img style={{width:"100%"}} src={roadmapImg}/> 
					: (
						<>
							<img style={{display:"block", width:"100%",maxWidth:"245px",margin:"auto"}} src={roadmapImgM01}/>
							<img style={{width:"100%"}} src={roadmapImgM02}/>
						</>
						)
					}
					
				</div>
			</div>
		</section>
	)

}