import '../../css/team/Team.css';
import '../../css/team/Team_res.css';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import adv01 from '../../img/team/ju_phil_jung.png';
import adv02 from '../../img/team/ji_wook_hong.png';
import adv03 from '../../img/team/min_kyu_park.png';
import adv04 from '../../img/team/ju_young_an.png';
import adv05 from '../../img/team/min_ah_paik.png';
import adv06 from '../../img/team/eun_ho_lim.png';
import adv07 from '../../img/team/hyung_jin_gong.png';


export default function Advisor(){

	const { t } = useTranslation();

	useEffect(()=>{
		const header = document.querySelector('header');
		header.setAttribute('data-vi','true');
	})

	const PEOPLE = [
		{
			img: adv01,
			rank: '',
			name: t('member-advisor-01--name'),
			careers : [
				t('member-advisor-01--career01'),
				t('member-advisor-01--career02'),
				t('member-advisor-01--career03'),
				t('member-advisor-01--career04'),
			]
		},
		{
			img: adv02,
			rank: '',
			name: t('member-advisor-02--name'),
			careers : [
				t('member-advisor-02--career01'),
				t('member-advisor-02--career02'),
				t('member-advisor-02--career03'),
			]
		},
		{
			img: adv03,
			rank: '',
			name: t('member-advisor-03--name'),
			careers : [
				t('member-advisor-03--career01'),
				t('member-advisor-03--career02'),
				t('member-advisor-03--career03'),
			]
		},
		{
			img: adv04,
			rank: '',
			name: t('member-advisor-04--name'),
			careers : [
				t('member-advisor-04--career01'),
				t('member-advisor-04--career02'),
				t('member-advisor-04--career03'),
			]
		},
		{
			img: adv05,
			rank: '',
			name: t('member-advisor-05--name'),
			careers : [
				t('member-advisor-05--career01'),
				t('member-advisor-05--career02'),
				t('member-advisor-05--career03'),
				t('member-advisor-05--career04'),
			]
		},
		{
			img: adv06,
			rank: '',
			name: t('member-advisor-06--name'),
			careers : [
				t('member-advisor-06--career01'),
				t('member-advisor-06--career02'),
				t('member-advisor-06--career03'),
			]
		},
		{
			img: adv07,
			rank: '',
			name: t('member-advisor-07--name'),
			careers : [
				t('member-advisor-07--career01'),
				t('member-advisor-07--career02'),
				t('member-advisor-07--career03'),
			]
		},
	]

	function profile(data) {

		function showCareer(career) {
			if (career && typeof career === "object" ) {
				if (career[0] === 'sub') {
					return <p className='profile-item__career-sub'>{career[1]}</p>
				}
				switch (career[1]) {
					case '<br/>':
						return <br/>
				
					default:
						return <p className='profile-item__career'></p>
				}
			}

			return <p className='profile-item__career'>{career}</p>
		}

		return (
			<li className='team__profile-item'>
				<div className='profile-item'>
					<div className='profile-item__img'>
						<img src={data.img}/>
					</div>
					<div className='profile-item__content'>
						<p className='profile-item__name'> <strong>{data.name}</strong> <span>{data.rank}</span> </p>
						{data.careers.map((item,key) => showCareer(item)) }
					</div>
				</div>
			</li>
		);
	}

	return (
		<section id="advisor">
			<div className='container'>
				<h1 className='team__title'>WADEX Advisor</h1>
				<ul className='team__profile-list'>
					{PEOPLE.map(item => profile(item))}
				</ul>
			</div>
		</section>
	)
	
}

