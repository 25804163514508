import { useTranslation } from 'react-i18next';
import '../../css/main/Platform.css';
import '../../css/main/Platform_res.css';
import TratToken from '../../img/watx_token_img_re.png'
import trat_token from '../../img/glovein_icon_token.png';
import trat_user from '../../img/glovein_icon_user.png';
import trat_nft from '../../img/glovein_icon_nft.png';
import chart from '../../img/allocation_chart.1.png';
import chart_mobile from '../../img/chart_mobile.1.png';
import { useEffect, useState } from 'react';


export default function Platform(){

	const { t } = useTranslation()

	function scrollAnimation(){
		if(!document.querySelector('.wrap-scroll-slide')) return

		let scroll = window.scrollY;
		const slide = document.querySelector('.wrap-scroll-slide').offsetTop;
		const leftChangePoint = slide + (window.outerHeight/2);
		const rightChangePoint = slide - scroll;
		const rightChangePoint2 = scroll - slide;

		if(window.outerWidth < 768){
			let bottomChangePoint = (window.outerHeight/2) * (rightChangePoint + (window.outerHeight/2)) / 100000;
			let bottomChangePoint2 = (window.outerHeight/2) * (rightChangePoint2 - (window.outerHeight/2)) / 100000;

			document.querySelector('.slide-txt-01').style = `opacity:${bottomChangePoint - 0.3}`;
			document.querySelector('.slide-txt-02').style = `opacity:${bottomChangePoint2}`;
		}else {
			document.querySelector('.slide-txt-01').style = `opacity:${(1 + (window.outerHeight * rightChangePoint)/1000000)}`;
			document.querySelector('.slide-txt-02').style = `opacity:${((window.outerHeight / 1.5) * rightChangePoint2)/1000000}`;
		}


		if(leftChangePoint < scroll){
			document.querySelector('.slide-img-02').style = 'opacity:1';
			document.querySelector('.slide-img-01').style = 'opacity:0';
		}else {
			document.querySelector('.slide-img-01').style = 'opacity:1';
			document.querySelector('.slide-img-02').style = 'opacity:0';
		}

	}

	useEffect(()=>{
		window.addEventListener('scroll', scrollAnimation);

		return ()=>{
			window.removeEventListener('scroll',scrollAnimation);
		}
	})

	return (
		<section id="platform">

			{/* <div className="wrap-token-container">
				<div id="tratToken" className="container">
					<h2 lang="en">GLOVEIN Allocation</h2>
					
					<div className="token-itr">
						<div className="wrap-item">
							<div className="item">
								<h4>{t('main_platform-token-00')}</h4>
								<p>{t('main_platform-token-01')}</p>
							</div>
							<div className="mb-d-view"></div>
							<div className="item">
								<h4>{t('main_platform-token-02')}</h4>
								<p>{t('main_platform-token-03')}</p>
							</div>
						</div>
						<div className="item item-img">
							<img src={TratToken} alt=""/>
						</div>
						<div className="wrap-item">
							<div className="item">
								<h4>{t('main_platform-token-04')}</h4>
								<p>{t('main_platform-token-05')}</p>
							</div>
							<div className="mb-d-view"></div>
							<div className="item">
								<h4>{t('main_platform-token-06')}</h4>
								<p>{t('main_platform-token-07')}</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}

			{/* <div className="wrap-wallet-container">
				<div className="container" id="traWallet">			
					<div className="wallet-card">
					<h3 lang="en">GLOVEIN</h3>
						<div className="contents">
							<h4>{t('main_about-howtopay_wallet-00')}</h4>
							<div className="wrap-txt">
								<p>{t('main_about-howtopay_wallet-01')}</p>
								<br/>
								<p>{t('main_about-howtopay_wallet-02')}</p>
							</div>
						</div>
						<img src={WalletImg} alt=""/>
					</div>
				</div>
			</div> */}



			<div className="container" id="ecoSystem">
				<div className="contents">
					
				</div>
				<div className="contents">
					<h3 lang="en">GLOV Token Eco-System</h3>
					<ul className="contents-itr">
						<li className="item">
							<div className="left-icon">
								<img src={trat_token} alt=""/>
							</div>
							<div className="right-txt">
								<p>{t('main_platform-ecoSystem-09')}</p>
							</div>
						</li>
						<li className="item">
							<div className="left-icon">
								<img src={trat_user} alt=""/>
							</div>
							<div className="right-txt">
								<p>{t('main_platform-ecoSystem-10')}</p>
							</div>
						</li>
						<li className="item">
							<div className="left-icon">
								<img src={trat_nft} alt=""/>
							</div>
							<div className="right-txt">
								<p>{t('main_platform-ecoSystem-11')}</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div className="wrap-allocation-container">
				<div lang="en" className="container" id="tratAllocation">
					<h3>GLOVEIN Allocation</h3>
					<div className="contents">
						<div className="left-chart-img">
							{window.outerWidth < 768 ? <img src={chart_mobile} alt=""/> : <img src={chart} alt=""/>}
						</div>
						<div className="right-chart-txt">
							<ul>
								<li><p>Platform Building </p><span>40&#37;</span></li>
								<li><p>Eco-system </p><span>22&#37;</span></li>
								<li><p>Public </p><span>17&#37;</span></li>
								<li><p>Team &amp; Advisor </p><span>8&#37;</span></li>
								<li><p>Reward </p><span>5&#37;</span></li>
								<li><p>Marketing </p><span>5&#37;</span></li>
								<li><p>Partnership </p><span>3&#37;</span></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}