import '../../css/main/Banner.css'
import '../../css/main/Banner_res.css'
// import Logo from '../../img/logo_watx_large.png';
import Logo from '../../img/logo_glovein_w.png';
// import download from '../../img/logo_download.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Banner(){

	const { t } = useTranslation()

	function toDown(){
		let scrollDownLength = window.outerHeight - 60;
		if(window.outerWidth < 768){
			scrollDownLength += 10;
		};

		window.scroll({behavior:'smooth',top: scrollDownLength});
	}


	return (
		<section className="banner">

			<video
				playsInline 
				muted 
				autoPlay 
				loop 
				src={'/images/tgn_banner.mp4'}
			></video>

			<div className="banner-cover"></div>

			<div className="container">
				<div className="contents">
					<img className='main-banner__logo' src={Logo} alt="GLOVEIN logo"/>
					<p className="cn-none" lang="en">The Beginning of a New Paradigm In Influencer</p>
					<p className="cn-block">基于区块链技术的ART交易服务</p>
				</div>

				<div lang="en" className='banner__btn-wrap'>
					<a id="" className='banner__btn' href={t('whitepaper-link')} target="_blank">WHITE PAPER</a>
					{/* <a id="" className='banner__btn' href="#">GLOVEIN MARKET</a> */}
				</div>

			</div>

			<div className="btn-scroll_down">
				<button lang="en" onClick={toDown}>SCROLL DOWN</button>
			</div>
		</section>
	)
}