import '../css/Footer.css';
// import logo_facebook from 

function Footer(){
	return (
		<footer lang="en">
			<div className="container">
				{/* <div className="footer-logo"></div> */}
				<p>WADEX PTE.LTD</p>
				<ul>
					<li><p>Business | Blockchain-based Global Influencer &amp; Investment Platform</p></li>
					<li><p>Founder | Jacob Lee</p></li>
					<li><address>Adress | 10 ANSON ROAD #34-10 INTERNATIONAL PLAZA SINGAPORE 079903</address></li>
				</ul>
				<address>
					E-MAIL | glovein@glovein.io <br/><br/>
				</address>

				<div>
					<h3 className="blind">sns</h3>
					{/* <ul className="ft-sns">
						<li><a title="facebook"> <p className="blind">facebook</p></a></li>
						<li><a title="tra homepage"> <p className="blind">tra</p></a></li>
						<li><a title="naver blog"> <p className="blind">naver blog</p></a></li>
						<li><a title="instagram"> <p className="blind">instagram</p></a></li>
					</ul> */}
				</div>
				
				<small>
					©Copyright GLOVEIN. All rights reserved.
				</small>

			</div>
		</footer>
	)
}

export default Footer;