import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import ko from './ko.json';
import en from './en.json';
import cn from './zh-CN.json';

const resources = {
	ko: {
		translation: ko
	},
	en: {
		translation: en
	},
	cn: {
		translation: cn
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: "en",
		fallbackLng: {
			default : ['en']
		}
	});

export default i18n;