import Banner from "./Banner"
import About from "./About"
import Platform from "./Platform"
import { useEffect } from "react"


export default function Main(){

	useEffect(()=>{
		document.querySelector('header').removeAttribute('data-vi','true');
	})

	return (
		<div>
			<Banner/>
			<About/>
			<Platform/>
		</div>
	)
}